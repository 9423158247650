.nav-bar {
  background: #181818;
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 60px;
      height: auto;
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        color: #ffd700;

        img {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: "";
        font-size: 15px;
        letter-spacing: 2px;
        position: absolute;
        top: calc(50% - 30px);
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: "HOME";
        }
      }
    }

    a.about-page {
      &::after {
        content: "ABOUT";
      }
    }

    a.project-page {
      &::after {
        content: "PROJECTS";
      }
    }

    a.contact-page {
      &::after {
        content: "CONTACT";
      }
    }

    a.active {
      img {
        filter: invert(100%);
        width: 35px;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 0%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;

        &:hover img {
          filter: invert(100%);
        }
      }
    }
  }
}

.hamburger-icon,
.cross-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    ul,
    nav {
      display: none;
    }

    nav {
      width: 100%;
      height: 100%;
      background: #181818;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 2;
      margin: 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a img {
        opacity: 1 !important;
      }

      &.mobile-show {
        display: block;
      }
    }

    .hamburger-icon,
    .cross-icon {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}
