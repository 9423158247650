.home-page {
  .text-zone {
    position: absolute;
    left: 15%;
    top: 40%;
    transform: translateY(-50deg);
    width: 55%;
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: "Source Code Pro";
    font-weight: 400;
  }
}

h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
  font-family: "sans-serif";
  animation: fadeIn 1s 1.8s backwards;
}

.flat-btn {
  color: #ffd700;
  font-weight: 400;
  font-size: 400;
  letter-spacing: 4px;
  font-family: "sans-serif";
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background-color: #ffd700;
    color: #333;
  }
}

.logo-container {
  z-index: -2;
  width: 750px;
  height: 600px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: auto;

  .solid-logo {
    position: absolute;
    right: auto;
    margin: auto;
    width: 100%;
    transform: rotateZ(20deg);
    z-index: 1;
    animation: fadeIn 1s;
    opacity: 0.8;
  }
}

@media screen and (max-width: 1200px) {
  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }

    .flat-btn {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 125px;
    }

    .logo-container {
      position: relative;
      width: 200px;
      height: auto;
      top: 50px;
      right: 0;
      box-sizing: border-box;
      margin: auto;
      left: 0;

      img {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
      }
    }
  }
}
