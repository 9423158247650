$priamry-color: #ffd700;

@import 'animate.css';
@import '~loaders.css/src/animations/ball-rotate.scss';

@font-face {
  font-family: 'MateSC Regular';
  src: url(./assets/fonts/MateSC-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Source Code Pro';
  src: url(./assets/fonts/SourceCodePro-ExtraLightItalic.ttf) format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url(./assets/fonts/LaBelleAurore-Regular.ttf) format('ttf');
}

input, textarea{
  font-family: 'Mate SC Regular';
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s forwards;
}