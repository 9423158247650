.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.about-page,
.container.contact-page,
.container.projects-page {
  h1 {
    font-size: 53px;
    font-family: "Mate SC";
    color: #ffd700;
    font-weight: 400;
    position: relative;
    margin-bottom: 40px;
    left: 10px;
  }

  p {
    font-size: 14px;
    color: #fff;
    font-family: "sans-serif";
    min-width: fit-content;
    animation: pulse 1s;
    letter-spacing: 1px;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }

    &:nth-of-type(4) {
      animation-delay: 1.4s;
    }

    &:nth-of-type(5) {
      animation-delay: 1.5s;
    }

    &:nth-of-type(6) {
      animation-delay: 1.6s;
    }
  }

  .text-zone {
    position: absolute;
    left: 15%;
    top: 20%;
    transform: translateY(-50deg);
    width: 55%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.about-page,
    &.contact-page,
    &.projects-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
}
