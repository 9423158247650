html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 "MateSC Regular", "sans-serif";
  color: #444;
  background: #022c43;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard {
  margin: 200px;
  color: #fff;
}

.btn {
  display: block;
  margin-top: 10px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}
