.projects-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: inherit;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0 0 20px 0 lightyellow;
    max-width: calc(25% - 63.25px);
    margin: 25px;

    .project-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
    }

    .techStack {
      color: #ffd700d7;
      font-weight: 600;
    }

    .description {
      font-size: 14px;
      margin-bottom: 2px;
      color: #fff;
      font-weight: 400;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #ffd700;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #ffd700;
    }

    &::after {
      content: "";
      background: linear-gradient(180deg, #ffd700, #000);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover::after {
      opacity: 0.857;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container.projects-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    h1.page-title {
      margin-left: 20px;
      margin-top: 20px;
    }

    .images-container {
      display: block;
    }

    .image-box {
      height: 200px;
      max-width: 100%;
    }
  }
}
