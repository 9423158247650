.skills {
  h2 {
    font-size: 30px;
    font-family: "Mate SC";
    color: #ffd700;
    font-weight: 400;
    position: relative;
    margin-bottom: 5px;
    left: 10px;
    margin-top: 30px;
  }

  p {
    animation-delay: 3s;
    margin-bottom: 20px;
  }

  .tech-tag {
    color: #ffd700;
  }
}

.stage-cube-cont {
  width: 30%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 lightyellow;

    img {
      width: 100px;
    }
  }

  .face1 {
    transform: translateZ(100px);
  }

  .face2 {
    transform: rotateX(90deg) translateZ(100px);
  }

  .face3 {
    transform: rotateX(90deg) rotateY(90deg) translateZ(100px);
  }

  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }

  .face5 {
    transform: rotateX(90deg) rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }

  .face6 {
    transform: rotateY(-90deg) translateZ(100px);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }

  16% {
    transform: rotateY(-90deg);
  }

  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }

  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }

  66% {
    transform: rotateY(-270deg) rotateZ(90deg);
  }

  83% {
    transform: rotateX(-90deg) rotateY(90deg) rotateZ(90deg);
  }
}

.no-text {
  height: 0;
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
    padding-top: 8%;
  }

  .no-text {
    height: 300px;
  }
}
